.footer-menu {
	padding-top: 15px;

	.footer_menu_category {
		font-weight: 400;
		margin-top: 20px;
	}

	a {
	}
}