//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

$mdc-typography-font-family: Roboto, sans-serif !default;

$mdc-typography-font-weight-values: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900
) !default;

/* TODO(sgomes): Figure out what to do about desktop font sizes. */

/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */

$mdc-typography-styles: (
  display4: (
    font-size: 7rem, /* 112sp */
    line-height: 7rem, /* 112sp */
    font-weight: map-get($mdc-typography-font-weight-values, light),
    letter-spacing: -.04em,
    margin: -1rem 0 3.5rem -.085em /* -16sp 0 56sp -.085em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  display3: (
    font-size: 3.5rem, /* 56px */
    line-height: 3.5rem, /* 56px */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: -.02em,
    margin: -8px 0 64px -.07em,
    text-decoration: inherit,
    text-transform: inherit
  ),
  display2: (
    font-size: 2.813rem, /* 45px */
    line-height: 3rem, /* 48px */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: normal,
    margin: -.5rem 0 4rem -.07em /* -8sp 0 64sp -.07em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  display1: (
    font-size: 2.125rem, /* 34sp */
    line-height: 2.5rem, /* 40sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: normal,
    margin: -.5rem 0 4rem -.07em /* -8sp 0 64sp -.07em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  headline: (
    font-size: 1.5rem, /* 24sp */
    line-height: 2rem, /* 32sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: normal,
    margin: -.5rem 0 1rem -.06em /* -8sp 0 16sp -.06em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  title: (
    font-size: 1.25rem, /* 20sp */
    line-height: 2rem, /* 32sp */
    font-weight: map-get($mdc-typography-font-weight-values, medium),
    letter-spacing: .02em,
    margin: -.5rem 0 1rem -.05em /* -8sp 0 16sp -.05em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  subheading2: (
    font-size: 1rem, /* 16sp */
    line-height: 1.75rem, /* 28sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: .04em,
    margin: -.5rem 0 1rem -.06em /* -8sp 0 16sp -.06em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  subheading1: (
    font-size: .938rem, /* 15sp */
    line-height: 1.5rem, /* 24sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: .04em,
    margin: -.313rem 0 .813rem -.06em /* -5sp 0 13sp -.06em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  body2: (
    font-size: .875rem, /* 14sp */
    line-height: 1.5rem, /* 24sp */
    font-weight: map-get($mdc-typography-font-weight-values, medium),
    letter-spacing: .04em,
    margin: -.25rem 0 .75rem 0 /* -4sp 0 12sp 0 */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  body1: (
    font-size: .875rem, /* 14sp */
    line-height: 1.25rem, /* 20sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: .04em,
    margin: -.25rem 0 .75rem 0 /* -4sp 0 12sp 0 */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  caption: (
    font-size: .75rem, /* 12sp */
    line-height: 1.25rem, /* 20sp */
    font-weight: map-get($mdc-typography-font-weight-values, regular),
    letter-spacing: .08em,
    margin: -.5rem 0 1rem -.04em /* -8sp 0 16sp -.04em */,
    text-decoration: inherit,
    text-transform: inherit
  ),
  button: (
    font-size: .875rem, /* 14sp */
    line-height: 2.25rem, /* 36sp */
    font-weight: map-get($mdc-typography-font-weight-values, medium),
    letter-spacing: .04em,
    margin: inherit /* We do not have adjust margin for button */,
    text-decoration: none,
    text-transform: uppercase
  )
) !default;
