//Material.io

@import "@material/elevation/mdc-elevation";
//@import "@material/grid-list/mdc-grid-list";
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/card/mdc-card";
@import "@material/button/mdc-button";
@import "@material/ripple/mdc-ripple";
@import "@material/image-list/mdc-image-list";
//@import "@material/drawer/mdc-drawer";
/*

@import "@material/card/mdc-card";
@import "@material/checkbox/mdc-checkbox";
@import "@material/dialog/mdc-dialog";
@import "@material/drawer/mdc-drawer";
@import "@material/fab/mdc-fab";
@import "@material/form-field/mdc-form-field";

@import "@material/icon-toggle/mdc-icon-toggle";

@import "@material/linear-progress/mdc-linear-progress";

@import "@material/menu/mdc-menu";
@import "@material/radio/mdc-radio";

@import "@material/select/mdc-select";
@import "@material/slider/mdc-slider";
@import "@material/snackbar/mdc-snackbar";
@import "@material/switch/mdc-switch";
@import "@material/tabs/mdc-tabs";
@import "@material/textfield/mdc-text-field";
@import "@material/theme/mdc-theme";
@import "@material/toolbar/mdc-toolbar";
@import "@material/typography/mdc-typography";
*/


// Part of bootstrap 4
@import 'bootstrap-selection';

$fa-font-path: '../vendor/font-awesome/fonts';
@import '../vendor/font-awesome/scss/font-awesome';

// Globbed variables
@import "/site/oelbydissing/assets/css/variables/colors.scss";
@import "/site/oelbydissing/assets/css/variables/font-sizes.scss";
@import "/site/oelbydissing/assets/css/variables/layout.scss";
@import "/site/oelbydissing/assets/css/variables/transitions.scss";

@import "components/flag-icon-css/sass/_variables.scss";

// Mixins
@import "/site/oelbydissing/assets/css/mixins/clearfix.scss";
@import "/site/oelbydissing/assets/css/mixins/font-size.scss";
@import "/site/oelbydissing/assets/css/mixins/full.scss";
@import "/site/oelbydissing/assets/css/mixins/hoverlink.scss";
@import "/site/oelbydissing/assets/css/mixins/no-smooth-font.scss";
@import "/site/oelbydissing/assets/css/mixins/single-image.scss";
@import "/site/oelbydissing/assets/css/mixins/text-shadow.scss";


// Mixins
@import "/site/oelbydissing/assets/css/components/bubble/bubble.scss";
@import "/site/oelbydissing/assets/css/components/button/btn.scss";
@import "/site/oelbydissing/assets/css/components/card/card-body.scss";
@import "/site/oelbydissing/assets/css/components/card/card.scss";
@import "/site/oelbydissing/assets/css/components/core/body.scss";
@import "/site/oelbydissing/assets/css/components/core/clearfix.scss";
@import "/site/oelbydissing/assets/css/components/core/pull-right.scss";
@import "/site/oelbydissing/assets/css/components/core/reset.scss";
@import "/site/oelbydissing/assets/css/components/core/text-center.scss";
@import "/site/oelbydissing/assets/css/components/features/code.scss";
@import "/site/oelbydissing/assets/css/components/features/fullvideo.scss";
@import "/site/oelbydissing/assets/css/components/features/splash-holder.scss";
@import "/site/oelbydissing/assets/css/components/flag-icon-css/sass/_flag-icon-base.scss";
@import "/site/oelbydissing/assets/css/components/flag-icon-css/sass/_flag-icon-list.scss";
@import "/site/oelbydissing/assets/css/components/flag-icon-css/sass/_flag-icon-more.scss";
@import "/site/oelbydissing/assets/css/components/flag-icon-css/sass/_variables.scss";
@import "/site/oelbydissing/assets/css/components/flag-icon-css/sass/flag-icon.scss";
@import "/site/oelbydissing/assets/css/components/grid/wide.scss";
@import "/site/oelbydissing/assets/css/components/horizontal_rules/colored-line.scss";
@import "/site/oelbydissing/assets/css/components/jumbotron/jumbotron.scss";
@import "/site/oelbydissing/assets/css/components/layout/footer.scss";
@import "/site/oelbydissing/assets/css/components/layout/header.scss";
@import "/site/oelbydissing/assets/css/components/layout/menu/footer-menu.scss";
@import "/site/oelbydissing/assets/css/components/layout/menu/screen-menu.scss";
@import "/site/oelbydissing/assets/css/components/layout/page-container.scss";
@import "/site/oelbydissing/assets/css/components/layout/page-wrapper.scss";
@import "/site/oelbydissing/assets/css/components/panel/gradient.scss";
@import "/site/oelbydissing/assets/css/components/panel/panel.scss";
@import "/site/oelbydissing/assets/css/components/panel/veil.scss";
@import "/site/oelbydissing/assets/css/components/screen/screen-padder.scss";
@import "/site/oelbydissing/assets/css/components/screen/screen.scss";
@import "/site/oelbydissing/assets/css/components/specific/blog/blog_article_teaser.scss";
@import "/site/oelbydissing/assets/css/components/specific/codebox.scss";
@import "/site/oelbydissing/assets/css/components/specific/documentation/docs-list.scss";
@import "/site/oelbydissing/assets/css/components/specific/documentation/markdown-block.scss";
@import "/site/oelbydissing/assets/css/components/specific/feature.scss";
@import "/site/oelbydissing/assets/css/components/specific/homepage/badges-holder.scss";
@import "/site/oelbydissing/assets/css/components/specific/login_img.scss";
@import "/site/oelbydissing/assets/css/components/specific/press/press.scss";
@import "/site/oelbydissing/assets/css/components/specific/stepnumber.scss";
@import "/site/oelbydissing/assets/css/components/specific/theme_manager/theme.scss";
@import "/site/oelbydissing/assets/css/components/table/table.scss";
@import "/site/oelbydissing/assets/css/components/tags/tag_holder.scss";
@import "/site/oelbydissing/assets/css/components/typography/anchor.scss";
@import "/site/oelbydissing/assets/css/components/typography/blockquote.scss";
@import "/site/oelbydissing/assets/css/components/typography/dark.scss";
@import "/site/oelbydissing/assets/css/components/typography/heading1.scss";
@import "/site/oelbydissing/assets/css/components/typography/heading2.scss";
@import "/site/oelbydissing/assets/css/components/typography/heading3.scss";
@import "/site/oelbydissing/assets/css/components/typography/heading5.scss";
@import "/site/oelbydissing/assets/css/components/typography/icon.scss";
@import "/site/oelbydissing/assets/css/components/typography/paragraph.scss";
@import "/site/oelbydissing/assets/css/components/typography/span.scss";
@import "/site/oelbydissing/assets/css/components/utilities/text-center.scss";


@import '../vendor/kiskabricks_wedgecss/wedge';
