// $scheme: (#505160, #68829E, #aedb38); // Crisp & Dramatic
// $scheme: (#375e97, #fb6542, #ffbb00); // Primary Colors With a Vibrant Twist
// $scheme: (#98DBC6, #5BC8AC, #E6D72A); // Refreshing & Pretty
// $scheme: (#1995ad, #a1d6e2, #bcbabe); // Icy Blues and Grays
// $scheme: (#5d535e, #ec96a4, #dfe166); // Birds & Berries
// $scheme: (#011a27, #063852, #e6df44); // Day & Night
// $scheme: (#011a27, #68829E, #51cdf1); // Day & Night
// $scheme: (#011a27, #557f97, #b7b7b7); // Day & Night
// $scheme: (#505160, #376aa1, #4CD4B0); // Crisp & Dramatic
//$scheme: (#505160, #2980B9, #44BBFF); // Crisp & Dramatic
$scheme: (#34804d, #156630, #154b66); // Lyd by Dissing


// applied scheme
$black: nth($scheme, 1);
$primary-color: nth($scheme, 2);
$secondary-color: nth($scheme, 3);

// basics
$white: #ffffff;
$grey: #423d3d;

// derived colors
$light-grey: lighten($grey, 55%);

$link-color: lighten($primary-color, 30%);

$primary-text-color: $grey;

$code-background: lighten(#000, 25%);

//$secondary-color-on-dark: saturate(lighten($secondary-color, 30%), 30%);
$secondary-color-on-dark: saturate(lighten($secondary-color, 20%), 90%);


$mdc-theme-primary: nth($scheme, 2); // Purple 500
$mdc-theme-secondary: nth($scheme, 3); // Orange A200
$mdc-theme-background: $white; // White
@import "@material/theme/mdc-theme";
