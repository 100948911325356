span {

	&.tiny {
		@include font-size(xs);
		font-weight: 100;
	}

	&.extra-tiny {
		@include font-size(xxs);
		font-weight: 100;
	}
}
