blockquote {
//	color: $white;
	margin-left: 25px;
	border-left: 10px solid $secondary-color;
	padding: 20px;
	padding-left: 25px;
	display: block;
//	background-color: $secondary-color-on-dark;
	background-color: $white;

	-webkit-filter: drop-shadow(2px 2px 2px #222);
	filter: drop-shadow(2px 2px 2px #222);

	p {
		margin-bottom: 0px;
	}

	p::before {
		padding-right: 10px;
  	font-family: "Material Icons";
		//font-size: 32px;
		color: $primary-color;
		content: "format_quote";
	}
	p::after {
		padding-left: 10px;
  	font-family: "Material Icons";
		//font-size: 32px;
		color: $primary-color;
		content: "format_quote";
	}
}
