.docs-list {
	padding: 30px;
	width: 100%;
	border: 1px solid lighten($grey, 70%);
	border-radius: 10px;
	margin-top: 40px;

	.navigation {
		font-weight: 400;
	}

	&.slime {
		position: fixed;
		top: 0;
	}

	li {

		a {
			text-decoration: none;
		}

		&.active {
			font-weight: 400;
		}

		&.side-level-3 {
			margin-left: 10px;
		}
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}