header {
	width: 100%;
	padding: 8px 30px 0px 10px;
	position: fixed;
	top: 0;
	background-color: $black;
	color: $white;
	z-index: 5;
	height: $header-height;
//	-webkit-filter: drop-shadow(3px 3px 3px #222);
//	filter: drop-shadow(3px 3px 3px #222);

	.logo {
		float: left;

		svg, img {
			height: 80px;

	//		-webkit-filter: drop-shadow(2px 2px 2px #222);
	//  	filter: drop-shadow(2px 2px 2px #222);

			path {
				fill: $white;
			}

			#e {
				fill: $black;
			}

			#lettering {
				stroke-width: 4px;
				stroke: $white;
			}
		}

	}

	@include media-breakpoint-down(sm) {
		padding: 8px 30px 3px 13px;
	}
}
