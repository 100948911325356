//
// Copyright 2016 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/ripple/mixins";
@import "@material/theme/mixins";
@import "./mixins";
@import "./variables";

// postcss-bem-linter: define button
.mdc-button {
  @include mdc-button-base_;
  @include mdc-button-corner-radius(2px);
  @include mdc-button-container-fill-color(transparent);
  @include mdc-button-ink-color(primary);
  @include mdc-states(primary);
}

.mdc-button--raised,
.mdc-button--unelevated {
  @include mdc-button--filled_;
  @include mdc-button-container-fill-color(primary);
  @include mdc-button-ink-color(text-primary-on-primary);
  @include mdc-states(text-primary-on-primary);
}

.mdc-button--raised {
  @include mdc-button--raised_;
}

.mdc-button--stroked {
  @include mdc-button--stroked_;
  @include mdc-button-stroke-width(2px);
  @include mdc-button-stroke-color(primary);
}

.mdc-button--compact {
  @include mdc-button--compact_;
}

.mdc-button--dense {
  @include mdc-button--dense_;
}

.mdc-button__icon {
  @include mdc-button__icon_;
}
// postcss-bem-linter: end
