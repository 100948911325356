.screen {
	width: 100vw;
	min-height: 100vh;
	background-size: cover;
	background-position: top center;
	position: relative;

	// modifiers
	&.black {
		background-color: $black;
	}

	&.primary_color {
		background-color: $primary-color;
		color: $white;
	}

	&.secondary_color {
		background-color: $secondary-color;
		color: $white;
	}

	&.colored {
		background-color: $primary-color;
		color: $white;
		border-top: 5px solid $secondary-color;

		a {
			color: lighten(saturate($primary-color, 100%), 50%);
			text-decoration: underline;
		}
	}

	&.fixed {
		position: fixed;
		top: 0;
	}


	&.below_header {
		min-height: calc(100vh - #{$header-height});
		height: calc(100vh - #{$header-height});
		margin-top: $header-height;
		/* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

		&__half {
			min-height: calc(75vh - #{$header-height});
			height: calc(75vh - #{$header-height});
		}
	}

}

.fixed-screen-holder {
	height: 100vh;
}
