// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$mdc-layout-grid-breakpoints: (
  desktop: 840px,
  tablet: 480px,
  phone: 0px
) !default;

$mdc-layout-grid-columns: (
  desktop: 12,
  tablet: 8,
  phone: 4
) !default;

$mdc-layout-grid-default-margin: (
  desktop: 24px,
  tablet: 16px,
  phone: 16px
) !default;

$mdc-layout-grid-default-gutter: (
  desktop: 24px,
  tablet: 16px,
  phone: 16px
) !default;

$mdc-layout-grid-column-width: (
  desktop: 72px,
  tablet: 72px,
  phone: 72px
) !default;

$mdc-layout-grid-default-column-span: 4 !default;

$mdc-layout-grid-max-width: null !default;
