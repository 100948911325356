//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/theme/variables";
@import "./variables";

/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
@mixin mdc-elevation($z-value, $color: $mdc-elevation-baseline-color, $opacity-boost: 0) {
  @if type-of($z-value) != number or not unitless($z-value) {
    @error "$z-value must be a unitless number, but received '#{$z-value}'";
  }

  @if $z-value < 0 or $z-value > 24 {
    @error "$z-value must be between 0 and 24, but received '#{$z-value}'";
  }

  @if map-has-key($mdc-theme-property-values, $color) {
    $color: map-get($mdc-theme-property-values, $color);
  }

  @if type-of($color) != color {
    @error "$color must be a valid color, but '#{$color}' is of type #{type-of($color)}";
  }

  $umbra-z-value: map-get($mdc-elevation-umbra-map, $z-value);
  $penumbra-z-value: map-get($mdc-elevation-penumbra-map, $z-value);
  $ambient-z-value: map-get($mdc-elevation-ambient-map, $z-value);

  $umbra-color: rgba($color, $mdc-elevation-umbra-opacity + $opacity-boost);
  $penumbra-color: rgba($color, $mdc-elevation-penumbra-opacity + $opacity-boost);
  $ambient-color: rgba($color, $mdc-elevation-ambient-opacity + $opacity-boost);

  box-shadow:
    #{"#{$umbra-z-value} #{$umbra-color}"},
    #{"#{$penumbra-z-value} #{$penumbra-color}"},
    #{$ambient-z-value} $ambient-color;
}

/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-rule(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
@function mdc-elevation-transition-rule(
  $duration: $mdc-elevation-transition-duration,
  $easing: $mdc-elevation-transition-timing-function) {
  @return #{$mdc-elevation-property} #{$duration} #{$easing};
}

/**
 * Applies the correct css rules needed to have an element transition between elevations.
 * This mixin should be applied to elements whose elevation values will change depending on their
 * context (e.g. when active or disabled).
 */
// NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
// be used in the same way by clients.
@mixin mdc-elevation-transition(
  $duration: $mdc-elevation-transition-duration,
  $easing: $mdc-elevation-transition-timing-function) {
  transition: mdc-elevation-transition-rule($duration, $easing);
  will-change: $mdc-elevation-property;
}
