footer {
	@include no-smooth-font();

	.logo svg {
		width: 100px;
		height: 50px;

		#justhexagon {
			fill: darken($white, 20%);
		}

		#juste {
			fill: $grey
		}
	}
}