h1 {
	@include font-size(xl);
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
	margin: 30px 0 10px 0;
	line-height: 1;

	// modifiers
	&.short {
		margin: 0;
	}

	&.large {
		@include font-size(xxl);
	}
}
