p {

	// modifiers
	&.padded {
		padding: 10px 0;
		margin-bottom: 0;
	}

	&.tiny {
		@include font-size(xs);
		font-weight: 100;
	}

	&.extra-tiny {
		@include font-size(xxs);
		font-weight: 100;
	}
}
