.btn {
	@include hoverlink();
	padding: 7px 30px;
	background-color: rgba($black, .2);
	border: 1px $white solid;
	display: inline-block;
	border-radius: 2px;
	margin: 0 5px;

	i {
		margin-top: -5px;
		margin-bottom: 10px;

		&.small {
			margin-top: 0;
			margin-bottom: 0;
			margin-left: -15px;
			margin-right: 15px;

			&:before {
				position: relative;
				top: 1px;
			}
		}
	}

	// modifiers
	$accent-button-color: saturate($primary-color, 40%);
	$accent-button-color-hover: saturate(darken($accent-button-color, 20%), 20%);
	&.accent {
		background-color: $accent-button-color;
		border-color: $white;
		color: $white;

		&:hover {
			background-color: $accent-button-color-hover;
		}
	}

	&.ghost {
		border: 1px solid $black;
		background-color: transparent;
	}

	&.icon {
		padding-right: 18px;
	}

/*	&.alignlow {
		position: absolute;
    bottom: 25px;
	} */

	// environments
	.black & {
		color: $white !important;
	}
}
