h2 {
	@include font-size(lg);
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
	margin: 50px 0 0 -2px;

	@include media-breakpoint-down(sm) {
		@include font-size(md);
		font-weight: 300;
	}

	// modifiers
	&.short {
		margin: 0;
	}

	&.active {
		// todo?
	}

	&.white {
		color: $white;
	}
}
