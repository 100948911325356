h3 {
	@include font-size(md);
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
	padding-bottom: 10px;

	// modifiers
	&.short {
		margin: 0;
		padding: 0;
	}

	&.line {
		border-bottom: 1px solid lighten($secondary-color, 15%);
		margin-bottom: 10px;
	}

}
