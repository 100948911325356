code {
	@include font-size(xs);
	display: inline !important;
	border-radius: 5px;
	padding: 2px 10px !important;
	margin: -5px 5px;
	background: $code-background !important;
	white-space: nowrap !important;
	font-family: 'Source Code Pro', monospace;
	color: darken($white, 10%);
}


// block code
pre code {
	display: inherit !important;
	padding: 15px 20px !important;
	margin: 0;
	white-space: pre-wrap !important;
}