.jumbotron {
	height: 450px;
	background-size: cover;
	background-position: center center;
	text-align: center;
	position: relative;

	.contentbox {
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}

	// modifiers
	&.top {
		background-position: top;
	}

	&.parallax {
		/* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	}

}
