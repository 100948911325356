@import "variables/colors.scss";

.page-container {
	padding: 0 100px;
	max-width: 1400px;
	margin: 0 auto;

	@include media-breakpoint-down(sm) {
		padding: 0 20px;
	}

	// modifiers
	&.padded {
		padding: 50px 70px;

		@include media-breakpoint-down(sm) {
			padding: 50px 20px;
		}
	}

	&.light-padded {
		padding: 25px 35px;

		@include media-breakpoint-down(sm) {
			padding: 25px 10px;
		}
	}

	&__grey {
		background-color: $light-grey;
	}

	&__border_top {
		border-top: 5px solid $secondary-color;
	}

}

.single-bg-image {
	//background-attachment: fixed;
	background-position: right center;
}
