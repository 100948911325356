body {
	@include font-size(sm);
	font-family: 'Roboto Slab', sans-serif;
	height: 100%;
	color: $primary-text-color;
	font-weight: 300;
	background-color: $white;
	overflow-x: hidden;
}

.blur-background {
	position: fixed;
	width: 100%;
	height: 100%;
	background-image: url(/assets/img/pattern.png);
	background-size: cover;
	z-index: -2;
	top: 0;
	overflow: hidden;

	&.light {
		//opacity: 0.6;
	}
}
