//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "./mixins";

:root {
  @each $style in map-keys($mdc-theme-property-values) {
    --mdc-theme-#{$style}: #{map-get($mdc-theme-property-values, $style)};
  }
}

// Special case, so that .mdc-theme--background changes background color, not text color.
.mdc-theme--background {
  @include mdc-theme-prop(background-color, background);
}

@each $style in map-keys($mdc-theme-property-values) {
  @if $style != "background" {
    .mdc-theme--#{$style} {
      @include mdc-theme-prop(color, $style, true);
    }
  }
}

// CSS rules for using primary and secondary (plus light/dark variants) as background colors.
@each $style in ("primary", "primary-light", "primary-dark", "secondary", "secondary-light", "secondary-dark") {
  .mdc-theme--#{$style}-bg {
    @include mdc-theme-prop(background-color, $style, true);
  }
}
