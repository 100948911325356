i {
	//@include font-size(icon2x, true);
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';

	/* Rules for sizing the icon. */
	/*.md-18 { font-size: 18px; }
	.md-24 { font-size: 24px; }
	.md-36 { font-size: 36px; }
	.md-48 { font-size: 48px; }
	.md-56 { font-size: 56px; }
	.md-72 { font-size: 72px; }*/
}
/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.md-56 { font-size: 56px; }
.material-icons.md-72 { font-size: 72px; }
